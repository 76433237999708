<template>
	<!-- container -->
	<div>
		<!-- titArea -->
		<div class="titArea">
			<div class="inner">
				<h2 class="txtL">공유회원 관리</h2>
				<a @click="myInfo()" class="btnBack">내정보로 이동</a>
			</div>
		</div>
		<!--// titArea -->
		<!-- contents -->
		<div class="contents">
			<!-- memberList -->
			<div class="memberList">
				<ul v-if="total > 0">
					<li v-for="(friend, index) in friendList" :key="index">
						<p class="icoNick">{{ friend.friendNickname }}</p>
						<p class="icoMemo">{{ friend.authority == 'A' ? '메모 및 댓글 쓰기 권한' : '댓글 쓰기 권한' }}</p>
						<p class="icoBuilding">{{ friend.buildingName }}</p>
						<a @click="onRemove(friend)" class="btnTrashB">삭제</a>
					</li>
				</ul>
				<ul v-else>
					<!-- noData -->
					<div class="noData">
						<p>등록된 공유회원이 없습니다.</p>
					</div>
				</ul>
			</div>
			<!--// memberList -->
		</div>
		<!--// contents -->
	</div>
	<!--// container -->
</template>
<script>
import friend from '@/api/friend';
import { showSpinner, hideSpinner } from '@/utils/cmm';
export default {
	mounted() {
		this.onGet();
	},
	data() {
		return {
			friendList: [],
			total: 0,
		};
	},
	methods: {
		async onGet() {
			try {
				showSpinner();
				let res = await friend.get('F');
				this.friendList = res.data;
				this.total = res.total;
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			} finally {
				hideSpinner();
			}
		},
		myInfo() {
			this.$router.push({ name: 'user' });
		},
		async onRemove(friendInfo) {
			if (
				!(await this.$confirm(
					`[${friendInfo.buildingName}] '${friendInfo.friendNickname}' 공유회원을 삭제하시겠습니까?`,
					'공유회원 안내',
				))
			)
				return false;
			showSpinner();
			try {
				await friend.remove(friendInfo.userId, friendInfo.friendId, friendInfo.buildingId);
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			} finally {
				hideSpinner();
			}
			await this.onGet();
		},
	},
};
</script>
